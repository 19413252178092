$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

$sidebar-border-color: #4c5462;
$sidebar-border-color-active: #21c1ef;
$sidebar-border-color-hover: #54c735;
$sidebar-vertical-padding: 16px;

$navbar-border-color: #b8b7b7;
$navbar-border-color-lighter: #bdbbbc;

$content-border-color: #e6e6e6;

$table-row-color-highlight: #f5f8fa;
$table-row-color-even: #f0f3f5;
$table-row-color-selected: #edf0f2;

$entity-actions-link-color: #4e515e;
$entity-actions-link-hover: #d1d4d6;

$panel-top-margin: 1.4rem;

$brand-primary:             #00bff0 !default;
$brand-success:             #4dc824 !default;
$brand-info:                #48a123 !default;
$brand-warning:             #f0ad4e !default;
$brand-danger:              #fb4a46 !default;

$body-bg:                    #f0f0f0 !default;

$placeholder-text-color:    #999;

$navbar-breakpoint: 544px;
$navbar-breakpoint-max: $navbar-breakpoint - 1px;
$navbar-header-height: 80px;